import axios from 'axios';
import pick from 'lodash/pick';
import qs from 'qs';

import { Memoize } from '@/decorators/memoize';
import {
  GlobalSpotOptions,
  IAccrualRule,
  IAccrualRuleFix,
  IAccrualRuleFixQuery,
  IAccrualRuleOfflineFix,
  IAccrualRuleOfflineFixQuery,
  IAccrualRuleType,
  IAdNetwork,
  IAdNetworkAccount,
  IAdNetworkSpot,
  IAdNetworkSpotLink,
  IAntiFraudVerdictGraduationList,
  IAuditLog,
  IAuditLogParams,
  IAuthResponse,
  IBatch,
  IBatchPayload,
  IDataTableQuery,
  IDynamicRevshareModerationRequest,
  IEmailHistory,
  IFAQArticle,
  IFAQCategory,
  IFingerprint,
  IFingerprintUser,
  IGoogleProperty,
  IMeta,
  INotificationRequest,
  INotificationSegment,
  IOptimizationRuleTest,
  IOwnOffer,
  IPaymentRequest,
  IPaymentRequestsQuery,
  IRate,
  IRateQueryParams,
  IResponse,
  IRestriction,
  IRole,
  ISession,
  ISetting,
  ISOptimizationRule,
  ISOptimizationRuleForm,
  ISpotBinding,
  ISpotOptimizationRule,
  ISpotOptimizationRuleQuery,
  ISpotPayload,
  ISpotSkinGroup,
  ISpotsListQuery,
  ISpotTest,
  IStaticRevshareModerationQuery,
  IStaticRevshareModerationRequest,
  ITag,
  ITagLabel,
  ITagTest,
  ITestGroup,
  ITrafficQuality,
  ITransaction,
  ITransactionsQuery,
  IUserExtended,
  IUserLabel,
  IUserLabelOptions,
  IUsersListQuery,
  IUserSpotOption,
  IUsersRestrictSuggestion,
  IUsersRestrictSuggestionsQuery,
  IUserValidatorData,
  IUserValidatorQuery,
  IYandexCounter,
  Loss,
  LossFilters,
  Manager,
  ModerationEntityType,
  ModerationRequest,
  Permission,
  SOptimizationMode,
  Spot,
  TagSpot,
  TestConfig,
  TrafficQualityReport,
} from '@/interfaces';
import { IUpsertAccrualRuleOfflineFix } from '@/interfaces/accrualRule';
import {
  IPaymentRequestApproveSettings,
  TrafficQualityReportFormatsResponse,
} from '@/interfaces/payment';
import {
  ISpotCustomLabel,
  ISpotMediation,
  ISpotMediationRulesQuery,
  ISpotSkin,
  SpotDefaults,
} from '@/interfaces/spot';
import {
  ITagOptimizationRuleLocal,
  ITagOptimizationRuleParams,
  ITagOptimizationRuleParamsRule,
  ITagTestGroup,
} from '@/interfaces/tag';
import {
  IUserLandingTemplate,
  IUserTechnicalAlert,
  IUserTechnicalAlertOptions,
  Referrer,
} from '@/interfaces/user';
import ApiService from '@/services/ApiService';
import {
  PAYMENT_REQUEST_PRE_APPROVE_STATUS,
  PAYMENT_REQUEST_PRE_REJECT_STATUS,
} from '@/utils/constants';
import { omitEmpty } from '@/utils/helpers';

class AdminService {
  async getUsers<K extends keyof IUserExtended>(params: IUsersListQuery<K>) {
    return ApiService.http.get<void, IResponse<Pick<IUserExtended, K>>>('/api/admin/users', {
      params,
    });
  }

  async getUser(id: IUserExtended['id']) {
    return await ApiService.http.get<void, { data: IUserExtended }>(`/api/admin/users/${id}`);
  }

  async editUser(user: IUserExtended) {
    return ApiService.http.patch<void, { data: IUserExtended }>(
      `/api/admin/users/${user.id}`,
      user
    );
  }

  async deleteUser(userId: IUserExtended['id']) {
    return ApiService.http.delete<void, void>(`/api/admin/users/${userId}`);
  }

  async getUserToken(id: IUserExtended['id']) {
    return ApiService.http.post<void, { data: IAuthResponse }>(`/api/admin/users/${id}/token`);
  }

  async validateEmails(params: IUserValidatorQuery) {
    return ApiService.http.get<void, IResponse<IUserValidatorData>>('/api/admin/users-search', {
      params,
    });
  }

  async getUserTransactions(params: ITransactionsQuery, userId: IUserExtended['id']) {
    return ApiService.http.get<void, IResponse<ITransaction>>(
      `/api/admin/users/${userId}/transactions`,
      { params }
    );
  }

  async storeTransaction(
    userId: IUserExtended['id'],
    params: Nullable<Pick<ITransaction, 'type' | 'amount' | 'extras'>>
  ) {
    return ApiService.http.post<void, { data: ITransaction }>(
      `/api/admin/users/${userId}/transactions`,
      params
    );
  }

  async getStaticRevshareModerationRequests(params: IStaticRevshareModerationQuery) {
    return ApiService.http.get<void, IResponse<IStaticRevshareModerationRequest>>(
      `/api/admin/revshare_change_requests`,
      { params }
    );
  }

  async approveStaticRevshareModerationRequest(request: IStaticRevshareModerationRequest) {
    return ApiService.http.put<void, void>(
      `/api/admin/revshare_change_requests/${request.id}`,
      request
    );
  }

  async rejectStaticRevshareModerationRequest(id: IStaticRevshareModerationRequest['id']) {
    return ApiService.http.delete<void, void>(`/api/admin/revshare_change_requests/${id}`);
  }

  async getStaticMediationRevshareModerationRequests(params: IStaticRevshareModerationQuery) {
    return ApiService.http.get<void, IResponse<IStaticRevshareModerationRequest>>(
      `/api/admin/mediation_revshare_change_requests`,
      { params }
    );
  }

  async approveStaticMediationRevshareModerationRequest(request: IStaticRevshareModerationRequest) {
    return ApiService.http.put<void, void>(
      `/api/admin/mediation_revshare_change_requests/${request.id}`,
      request
    );
  }

  async rejectStaticMediationRevshareModerationRequest(id: IStaticRevshareModerationRequest['id']) {
    return ApiService.http.delete<void, void>(
      `/api/admin/mediation_revshare_change_requests/${id}`
    );
  }

  async getDynamicRevshareModerationRequests(params: IDataTableQuery) {
    return ApiService.http.get<void, IResponse<IDynamicRevshareModerationRequest>>(
      `/api/admin/dynamic_revshare_change_requests`,
      { params }
    );
  }

  async approveDynamicRevshareModerationRequest(request: IDynamicRevshareModerationRequest) {
    return ApiService.http.put<void, void>(
      `/api/admin/dynamic_revshare_change_requests/${request.id}`,
      request
    );
  }

  async rejectDynamicRevshareModerationRequest(id: IDynamicRevshareModerationRequest['id']) {
    return ApiService.http.delete<void, void>(`/api/admin/dynamic_revshare_change_requests/${id}`);
  }

  async getGlobal(key: string) {
    return ApiService.http.get<void, { data: { value: NotNullable<ISetting['value']> } }>(
      `/api/admin${key === 'blocked-tag-domain' ? '' : '/settings'}/${key}`
    );
  }

  async setGlobal(params: Pick<ISetting, 'setting' | 'value'>) {
    return ApiService.http.patch<void, { data: { value: NotNullable<ISetting['value']> } }>(
      `/api/admin${params.setting === 'blocked-tag-domain' ? '' : '/settings'}/${params.setting}`,
      params
    );
  }

  async changeReferrer(referral_id: IUserExtended['id'], referrer_id: Referrer['id'] | null) {
    return ApiService.http.patch<void, { data: Referrer }>(`/api/admin/referrers`, {
      referral_id,
      referrer_id,
    });
  }
  async assignManager(userId: IUserExtended['id'], manager_id?: Manager['id']) {
    return ApiService.http.post<void, { data: Manager }>(
      `/api/admin/users/${userId}/manager-assignments`,
      {
        ...(manager_id != null && { manager_id }),
      }
    );
  }

  async reassignManager(
    userId: IUserExtended['id'],
    managerId: Manager['id'],
    assignmentId: number
  ) {
    return ApiService.http.put<void, { data: Manager }>(
      `/api/admin/users/${userId}/manager-assignments/${assignmentId}`,
      {
        manager_id: managerId,
      }
    );
  }

  async unassignManager(userId: IUserExtended['id'], assignmentId: number) {
    return ApiService.http.delete<void, void>(
      `/api/admin/users/${userId}/manager-assignments/${assignmentId}`
    );
  }

  async getLogs(adformat: AdFormat) {
    return ApiService.http.get<void, { data: [] }>(`/api/admin/${adformat}_service/logs`);
  }

  async getAuditableTypes() {
    return ApiService.http.get<void, { data: string[] }>('/api/admin/audit/auditable');
  }

  async getAuditLogs(params: IAuditLogParams) {
    return ApiService.http.get<void, IResponse<IAuditLog>>('/api/admin/audit', { params });
  }

  async getTags(
    params: Partial<IDataTableQuery> & {
      publisher_id: number | null;
      name?: string | null;
      id?: number | null;
      custom_tag_label?: ITagLabel['id'] | null;
    }
  ) {
    return ApiService.http.get<void, IResponse<ITag>>(`/api/admin/tags`, { params });
  }

  async getTag(id: ITag['id']) {
    return ApiService.http.get<void, { data: ITag }>(`/api/admin/tags/${id}`);
  }

  async createTag(
    params: Pick<ITag, 'name' | 'category_id'> & {
      spots: TagSpot[];
      require_spots: boolean;
      publisher_id?: number;
    }
  ) {
    return ApiService.http.post<void, { data: ITag }>(`/api/admin/tags`, params);
  }

  async updateTag(
    id: ITag['id'],
    params: Partial<
      Pick<
        ITag,
        | 'name'
        | 'category_id'
        | 'custom_tag_labels'
        | 'options'
        | 'yandex_metrika_counter'
        | 'google_analytics_property'
      >
    >
  ) {
    return ApiService.http.patch<void, { data: ITag }>(`/api/admin/tags/${id}`, params);
  }

  async deleteTag(id: ITag['id']) {
    return ApiService.http.delete<void, void>(`/api/admin/tags/${id}`);
  }

  async restoreTag(id: ITag['id']) {
    return ApiService.http.post<void, { data: ITag }>(`/api/admin/tags/${id}/restore`);
  }

  async copyTag(id: ITag['id'], params: { name: ITag['name'] }) {
    return ApiService.http.post<void, { data: ITag }>(`/api/admin/tags/${id}/copy`, params);
  }

  async getYandexCounters(userId: IUserExtended['id']) {
    const {
      data: { counters },
    } = await ApiService.http.get<void, { data: { counters: IYandexCounter[] } }>(
      `/api/admin/yandex/get_counters_list/${userId}`
    );
    return counters;
  }

  async getGoogleProperties(userId: IUserExtended['id']) {
    const {
      data: { properties },
    } = await ApiService.http.get<void, { data: { properties: IGoogleProperty[] } }>(
      `/api/admin/google/get_properties_list/${userId}`
    );
    return properties;
  }

  async fetchTest(id: ITagTest['id']) {
    return ApiService.http.get<void, { data: ITagTest }>(`/api/admin/tag-tests/${id}`);
  }

  async createTest(params: Pick<ITagTest, 'name' | 'tag_id' | 'tag_test_group_id'>) {
    return ApiService.http.post<void, { data: ITagTest }>('/api/admin/tag-tests', params);
  }

  async updateTest(params: Pick<ITagTest, 'id' | 'tag_test_group_id'>) {
    return ApiService.http.put<void, { data: ITagTest }>(
      `/api/admin/tag-tests/${params.id}`,
      pick(params, 'tag_test_group_id')
    );
  }

  async deleteTest(id: ITagTest['id']) {
    return ApiService.http.delete<void, void>(`/api/admin/tag-tests/${id}`);
  }

  async updateConfig(
    id: ITagTest['id'],
    config: Exclude<TestConfig, 'a'>,
    params: {
      spots: Pick<
        TagSpot,
        'additional_options' | 'adformat' | 'hidden' | 'options' | 'id' | 'visible_for_publisher'
      >[];
    }
  ) {
    return ApiService.http.put<void, void>(`/api/admin/tag-tests/${id}/configs/${config}`, params);
  }

  async copyTestSpotConfigs(
    id: ITagTest['id'],
    params: {
      spots: Pick<
        TagSpot,
        'additional_options' | 'adformat' | 'hidden' | 'options' | 'id' | 'visible_for_publisher'
      >[];
    }
  ) {
    return ApiService.http.post<void, void>(`/api/admin/tag-tests/${id}/copy-spot-options`, params);
  }

  async getTagOptimizationRules(tag_id: ITagOptimizationRuleLocal['tag_id']) {
    return ApiService.http.get<void, { data: ITagOptimizationRuleParams }>(
      `/api/admin/tag-optimization-rules/${tag_id}/list`
    );
  }

  async createTagOptimizationRules(params: ITagOptimizationRuleParams) {
    return ApiService.http.post<void, { data: ITagOptimizationRuleParams }>(
      `/api/admin/tag-optimization-rules`,
      params
    );
  }

  async updateTagOptimizationRule(
    rule_id: ITagOptimizationRuleLocal['id'],
    params: ITagOptimizationRuleParamsRule
  ) {
    return ApiService.http.patch<void, { data: ITagOptimizationRuleParamsRule }>(
      `/api/admin/tag-optimization-rules/${rule_id}`,
      params
    );
  }

  async deleteTagOptimizationRule(rule_id: number) {
    return ApiService.http.delete<void, void>(`/api/admin/tag-optimization-rules/${rule_id}`);
  }

  async getTagTestGroups() {
    return ApiService.http.get<void, { data: ITagTestGroup[] }>('/api/admin/tag-test-groups');
  }

  async createTagTestGroup(params: Pick<ITagTestGroup, 'name'>) {
    return ApiService.http.post<void, { data: ITagTestGroup }>(
      '/api/admin/tag-test-groups',
      params
    );
  }

  async deleteTagTestGroup(id: ITagTestGroup['id']) {
    return ApiService.http.delete<void, void>(`/api/admin/tag-test-groups/${id}`);
  }

  async fetchTagLabels(params: IDataTableQuery) {
    return ApiService.http.get<void, IResponse<ITagLabel>>('/api/admin/custom-tag-labels', {
      params,
    });
  }

  async getTestGroups() {
    return ApiService.http.get<void, IResponse<ITestGroup>>('/api/admin/test-groups');
  }

  async createTestGroup(params: Pick<ITestGroup, 'name'>) {
    return ApiService.http.post<void, { data: ITestGroup }>('/api/admin/test-groups', params);
  }

  async deleteTestGroup(id: ITestGroup['id']) {
    return ApiService.http.delete<void, void>(`/api/admin/test-groups/${id}`);
  }

  async getOptimizationRuleTests(id: ITestGroup['id']) {
    return ApiService.http.get<void, { data: IOptimizationRuleTest[] }>(
      `/api/admin/test-group/${id}/optimization-rules`
    );
  }

  async createOptimizationRuleTest(
    params: Omit<IOptimizationRuleTest, 'created_at' | 'id' | 'updated_at'> & {
      test_group_id: ITestGroup['id'];
    }
  ) {
    return ApiService.http.post<void, { data: IOptimizationRuleTest }>(
      '/api/admin/test-group/optimization-rules',
      params
    );
  }

  async updateOptimizationRuleTest(
    id: IOptimizationRuleTest['id'],
    params: Omit<IOptimizationRuleTest, 'created_at' | 'id' | 'updated_at'>
  ) {
    return ApiService.http.put<void, { data: IOptimizationRuleTest }>(
      `/api/admin/test-group/optimization-rules/${id}`,
      params
    );
  }

  async deleteOptimizationRuleTest(id: IOptimizationRuleTest['id']) {
    return ApiService.http.delete<void, void>(`/api/admin/test-group/optimization-rules/${id}`);
  }

  async getSpotTests(id: ITestGroup['id']) {
    return ApiService.http.get<void, { data: ISpotTest[] }>(`/api/admin/test-group/${id}/spots`);
  }

  async createSpotTest(
    params: Omit<ISpotTest, 'created_at' | 'id' | 'updated_at'> & {
      test_group_id: ITestGroup['id'];
    }
  ) {
    return ApiService.http.post<void, { data: ISpotTest }>(`/api/admin/test-group/spots`, params);
  }

  async updateSpotTest(
    id: ISpotTest['id'],
    params: Omit<ISpotTest, 'created_at' | 'id' | 'updated_at'>
  ) {
    return ApiService.http.put<void, { data: ISpotTest }>(
      `/api/admin/test-group/spots/${id}`,
      params
    );
  }

  async deleteSpotTest(id: ISpotTest['id']) {
    return ApiService.http.delete<void, void>(`/api/admin/test-group/spots/${id}`);
  }

  async getSpots(params: ISpotsListQuery, adformat: AdFormat) {
    return ApiService.http.get<void, IResponse<Spot>>(
      `/api/admin/spots/${adformat}?${qs.stringify(omitEmpty(params), {
        encode: true,
        arrayFormat: 'brackets',
      })}`
    );
  }

  async getSpot<T extends AdFormat>(id: Spot['id'], adformat: T) {
    return ApiService.http.get<void, { data: Spot<T> }>(`/api/admin/spots/${adformat}/${id}`);
  }

  async getSpotsInCSV(params: ISpotsListQuery & { notify_email?: boolean }, adformat: AdFormat) {
    return ApiService.http.get<void, any>(
      `/api/admin/spots/${adformat}/csv?${qs.stringify(omitEmpty(params), {
        encode: true,
        arrayFormat: 'brackets',
      })}`
    );
  }

  async getUsersInCSV(params: IUserValidatorQuery) {
    return ApiService.http.get<void, Blob>(
      `api/admin/users-search/csv?${qs.stringify(omitEmpty(params), {
        encode: true,
        arrayFormat: 'brackets',
      })}`,
      { responseType: 'blob' }
    );
  }

  async getTagSpots<T extends AdFormat>(
    id: ITag['id'],
    adformat: T,
    params: Partial<IDataTableQuery> & Partial<Nullable<{ name: string; id: string | number }>>
  ) {
    return ApiService.http.get<void, IResponse<Spot<T>>>(
      `/api/admin/tags/${id}/spots/${adformat}`,
      { params }
    );
  }

  async addSpot<T extends AdFormat>(params: ISpotPayload<T>, adformat: T) {
    return ApiService.http.post<void, { data: Spot<T> }>(`/api/admin/spots/${adformat}`, params);
  }

  async updateSpot<T extends AdFormat>(id: number, params: ISpotPayload<T>, adformat: T) {
    return ApiService.http.patch<void, { data: Spot<T> }>(
      `/api/admin/spots/${adformat}/${id}`,
      params
    );
  }

  async deleteSpot(id: Spot['id'], adformat: AdFormat) {
    return ApiService.http.delete<void, void>(`/api/admin/spots/${adformat}/${id}`);
  }

  async restoreSpot(id: Spot['id'], adformat: AdFormat) {
    return ApiService.http.post<void, { data: Spot }>(`/api/admin/spots/${adformat}/${id}/restore`);
  }

  async copySpot<T extends AdFormat>(
    id: Spot['id'],
    adFormat: T,
    params: { tag_id: ITag['id']; name: Spot['name']; with_revshares?: true }
  ) {
    return ApiService.http.post<void, { data: Spot<T> }>(
      `/api/admin/spots/${adFormat}/${id}/copy`,
      params
    );
  }

  async hideSpot<T extends AdFormat>(id: Spot['id'], adformat: T) {
    return ApiService.http.post<void, { data: Spot<T> }>(`/api/admin/spots/${adformat}/${id}/hide`);
  }

  async unhideSpot<T extends AdFormat>(id: Spot['id'], adformat: T) {
    return ApiService.http.post<void, { data: Spot<T> }>(
      `/api/admin/spots/${adformat}/${id}/unhide`
    );
  }

  async banSpot<T extends AdFormat>(id: Spot['id'], adformat: T) {
    return ApiService.http.post<void, { data: Spot<T> }>(`api/admin/spots/${adformat}/${id}/ban`);
  }

  async unbanSpot<T extends AdFormat>(id: Spot['id'], adformat: T) {
    return ApiService.http.post<void, { data: Spot<T> }>(`api/admin/spots/${adformat}/${id}/unban`);
  }

  @Memoize
  async getSpotDefaults<T extends AdFormat>(adformat: T, user_id?: IUserExtended['id']) {
    return ApiService.http.get<void, { data: SpotDefaults<T> }>(`/api/admin/spots/create`, {
      params: {
        adformat,
        user_id,
      },
    });
  }

  async downloadServiceWorker<T extends Extract<AdFormat, 'push'>>(id: Spot['id'], adFormat: T) {
    return ApiService.http.get<void, string>(`/api/admin/spots/${adFormat}/${id}/worker`);
  }

  async getSpotMediations(params: Partial<ISpotMediationRulesQuery>) {
    return ApiService.http.get<void, IResponse<ISpotMediation>>(
      `/api/admin/spots/${params.adFormat}/${params.spotID}/spot-mediations`,
      { params }
    );
  }

  async addSpotMediation(
    adFormat: Extract<AdFormat, 'inpage_push'>,
    spotID: number,
    params: Omit<ISpotMediation, 'id' | 'author_id'>
  ) {
    return ApiService.http.post<void, { data: ISpotMediation }>(
      `/api/admin/spots/${adFormat}/${spotID}/spot-mediations`,
      params
    );
  }

  async updateSpotMediation(
    adFormat: Extract<AdFormat, 'inpage_push'>,
    spotID: number,
    params: Nullable<ISpotMediation, 'id' | 'author_id'>
  ) {
    return ApiService.http.put<void, { data: ISpotMediation }>(
      `/api/admin/spots/${adFormat}/${spotID}/spot-mediations/${params.id}`,
      params
    );
  }

  async deleteSpotMediation(
    adFormat: Extract<AdFormat, 'inpage_push'>,
    spotID: number,
    id: ISpotMediation['id']
  ) {
    return ApiService.http.delete<void, void>(
      `/api/admin/spots/${adFormat}/${spotID}/spot-mediations/${id}`
    );
  }

  async getSpotBindings(userId: IUserExtended['id'], adFormat: AdFormat, spotId: Spot['id']) {
    return ApiService.http.get<void, { data: ISpotBinding[] }>(
      `/api/admin/users/${userId}/adformats/${adFormat}/spots/${spotId}/spots-bindings`
    );
  }

  async addSpotBinding(
    userId: IUserExtended['id'],
    from_spot_adformat: AdFormat,
    from_spot_id: Spot['id'],
    params: Pick<ISpotBinding, 'to_spot_id' | 'to_spot_adformat' | 'redistribution_value'>
  ) {
    return ApiService.http.post<void, { data: ISpotBinding }>(
      `/api/admin/users/${userId}/adformats/${from_spot_adformat}/spots/${from_spot_id}/spots-bindings`,
      params
    );
  }

  async updateSpotBinding(
    userId: IUserExtended['id'],
    adFormat: AdFormat,
    spotId: Spot['id'],
    bindingId: ISpotBinding['id'],
    params: Pick<ISpotBinding, 'to_spot_id' | 'to_spot_adformat' | 'redistribution_value'>
  ) {
    return ApiService.http.patch<void, { data: ISpotBinding }>(
      `/api/admin/users/${userId}/adformats/${adFormat}/spots/${spotId}/spots-bindings/${bindingId}`,
      params
    );
  }

  async removeSpotBinding(
    userId: IUserExtended['id'],
    adFormat: AdFormat,
    spotId: Spot['id'],
    bindingId: ISpotBinding['id']
  ) {
    return ApiService.http.delete<void, void>(
      `/api/admin/users/${userId}/adformats/${adFormat}/spots/${spotId}/spots-bindings/${bindingId}`
    );
  }

  async getSkinGroups(adFormat: Extract<AdFormat, 'inpage_push'>) {
    return ApiService.http.get<void, { data: ISpotSkinGroup[] }>(
      `/api/admin/spot-optimization-rules/${adFormat}/proxy/skin-groups`
    );
  }

  async getSkins(adFormat: Extract<AdFormat, 'inpage_push'>) {
    return ApiService.http.get<void, { data: ISpotSkin[] }>(
      `/api/admin/spot-optimization-rules/${adFormat}/proxy/skins`
    );
  }

  async getSpotOptimizationRules(
    spotId: Spot['id'],
    adFormat: AdFormat,
    params: { version_name?: TestConfig }
  ) {
    return ApiService.http.get<void, IResponse<ISpotOptimizationRule>>(
      `/api/admin/spots/${adFormat}/${spotId}/optimization-rules`,
      { params }
    );
  }

  async fetchOptimizationRules(params: ISpotOptimizationRuleQuery) {
    return ApiService.http.get<void, IResponse<ISpotOptimizationRule>>(
      '/api/admin/spot-optimization-rules-by-criteria',
      { params }
    );
  }

  async addSpotOptimizationRule(
    spotId: Spot['id'],
    adFormat: AdFormat,
    params: ISpotOptimizationRule
  ) {
    return ApiService.http.post<void, void>(
      `/api/admin/spots/${adFormat}/${spotId}/optimization-rules`,
      params
    );
  }

  async updateSpotOptimizationRule(
    spotId: Spot['id'],
    adFormat: AdFormat,
    ruleId: ISpotOptimizationRule['id'],
    params: ISpotOptimizationRule
  ) {
    return ApiService.http.patch<void, { data: ISpotOptimizationRule }>(
      `/api/admin/spots/${adFormat}/${spotId}/optimization-rules/${ruleId}`,
      params
    );
  }

  async removeSpotOptimizationRule(
    spotId: Spot['id'],
    adFormat: AdFormat,
    ruleId: ISpotOptimizationRule['id'],
    params: { version_name: TestConfig }
  ) {
    return ApiService.http.delete<void, void>(
      `/api/admin/spots/${adFormat}/${spotId}/optimization-rules/${ruleId}`,
      { params }
    );
  }

  async getAdFormatSpotOptimizationRules(userId: IUserExtended['id'], adFormat: AdFormat) {
    return ApiService.http.get<void, IResponse<ISpotOptimizationRule>>(
      `/api/admin/users/${userId}/adformats/${adFormat}/adformat-optimization-rules`
    );
  }

  async addAdFormatSpotOptimizationRule(
    userId: IUserExtended['id'],
    adFormat: AdFormat,
    params: ISpotOptimizationRule
  ) {
    return ApiService.http.post<void, { data: ISpotOptimizationRule }>(
      `/api/admin/users/${userId}/adformats/${adFormat}/adformat-optimization-rules`,
      params
    );
  }

  async updateAdFormatSpotOptimizationRule(
    userId: IUserExtended['id'],
    adFormat: AdFormat,
    ruleId: ISpotOptimizationRule['id'],
    params: ISpotOptimizationRule
  ) {
    return ApiService.http.patch<void, { data: ISpotOptimizationRule }>(
      `/api/admin/users/${userId}/adformats/${adFormat}/adformat-optimization-rules/${ruleId}`,
      params
    );
  }

  async removeAdFormatSpotOptimizationRule(
    userId: IUserExtended['id'],
    adFormat: AdFormat,
    ruleId: ISpotOptimizationRule['id']
  ) {
    return ApiService.http.delete<void, void>(
      `/api/admin/users/${userId}/adformats/${adFormat}/adformat-optimization-rules/${ruleId}`
    );
  }

  async getSOptimizationRuleTypes() {
    return ApiService.http.get<void, { data: Record<number, string> }>(
      `/api/admin/s-optimization-rule-types`
    );
  }

  async getSOptimizationRules<T extends SOptimizationMode>(
    params: IDataTableQuery,
    userId: IUserExtended['id']
  ) {
    return ApiService.http.get<void, IResponse<ISOptimizationRule<T>>>(
      `/api/admin/users/${userId}/s-optimization-rules`,
      { params }
    );
  }

  async addSOptimizationRule(
    params: ISOptimizationRuleForm<'common'>,
    userId: IUserExtended['id']
  ) {
    return ApiService.http.post<void, void>(
      `/api/admin/users/${userId}/s-optimization-rules`,
      params
    );
  }

  async stopSOptimizationRule<T extends SOptimizationMode>(
    ruleId: ISOptimizationRule<T>['id'],
    userId: IUserExtended['id']
  ) {
    return ApiService.http.delete<void, { data: ISOptimizationRule<T> }>(
      `/api/admin/users/${userId}/s-optimization-rules/${ruleId}`
    );
  }

  async getSystemSOptimizationRuleTypes() {
    return ApiService.http.get<void, { data: Record<number, string> }>(
      `/api/admin/system-s-optimization-rule-types`
    );
  }

  async getSystemSOptimizationRules<T extends SOptimizationMode>(
    params: IDataTableQuery,
    userId: IUserExtended['id']
  ) {
    userId; // TODO: Remove this
    return ApiService.http.get<void, IResponse<ISOptimizationRule<T>>>(
      `/api/admin/system-s-optimization-rules`,
      { params }
    );
  }

  async addSystemSOptimizationRule<T extends SOptimizationMode>(
    params: ISOptimizationRuleForm<'system'>
  ) {
    return ApiService.http.post<void, { data: ISOptimizationRule<T> }>(
      `/api/admin/system-s-optimization-rules`,
      params
    );
  }

  async stopSystemSOptimizationRule<T extends SOptimizationMode>(
    ruleId: ISOptimizationRule<T>['id'],
    userId: IUserExtended['id']
  ) {
    userId; // TODO: Remove this
    return ApiService.http.delete<void, { data: ISOptimizationRule<T> }>(
      `/api/admin/system-s-optimization-rules/${ruleId}`
    );
  }

  async fetchAccrualRuleFixes(params: IAccrualRuleFixQuery) {
    return ApiService.http.get<void, IResponse<IAccrualRuleFix>>(
      `/api/admin/accrual-rules/report`,
      { params }
    );
  }

  async getAccrualRuleTypes() {
    return ApiService.http.get<
      void,
      { data: Record<NotNullable<IAccrualRuleType['id']>, IAccrualRuleType['name']> }
    >(`/api/admin/accrual-rule-types`);
  }

  async getAccrualRules(userId: IUserExtended['id'], params: IDataTableQuery) {
    return ApiService.http.get<void, IResponse<IAccrualRule>>(
      `/api/admin/users/${userId}/accrual-rules`,
      { params }
    );
  }

  async addAccrualRule(params: IAccrualRule & { user_id: IUserExtended['id'] }) {
    return ApiService.http.post<void, void>(
      `/api/admin/users/${params.user_id}/accrual-rules`,
      params
    );
  }

  async deleteAccrualRule(params: IAccrualRule & { user_id: IUserExtended['id'] }) {
    return ApiService.http.delete<void, void>(
      `/api/admin/users/${params.user_id}/accrual-rules/${params.id}`
    );
  }

  async getNetTerms() {
    return ApiService.http.get<void, { data: { value: number } }>(`/api/admin/net_terms`);
  }

  async saveNetTerms(value: number) {
    return ApiService.http.post<void, { data: { value: number } }>(`/api/admin/net_terms`, {
      value,
    });
  }

  async getUserNetTerms(id: number) {
    return ApiService.http.get<void, { data: { effective_net_term: number | null } }>(
      `/api/admin/users/${id}/effective_net_term`
    );
  }

  async saveUserNetTerms(id: number, value: number) {
    return ApiService.http.post<void, { data: { value: number } }>(
      `/api/admin/users/${id}/net_terms`,
      { value }
    );
  }

  async getRestrictions(userId: IUserExtended['id']) {
    return ApiService.http.get<void, { data: IRestriction[] }>(
      `/api/admin/users/${userId}/restrictions`
    );
  }

  async addRestriction(userId: IUserExtended['id'], params: Partial<IRestriction>) {
    return ApiService.http.post<void, { data: IRestriction }>(
      `/api/admin/users/${userId}/restrictions`,
      params
    );
  }

  async updateRestriction(userId: IUserExtended['id'], params: IRestriction) {
    return ApiService.http.put<void, { data: IRestriction }>(
      `/api/admin/users/${userId}/restrictions/${params.id}`,
      params
    );
  }

  async fetchGlobalSpotOptions<T extends AdFormat>(adformat: T) {
    return ApiService.http.get<void, { data: GlobalSpotOptions<T> }>(
      `/api/admin/spot-options/${adformat}`
    );
  }

  async updateGlobalSpotOptions<T extends AdFormat>(
    adformat: T,
    data: {
      options: Omit<Spot<T>, 'mediation'>;
      additional_options: Spot<T>['additional_options'];
    }
  ) {
    return ApiService.http.put<void, { data: GlobalSpotOptions<T> }>(
      `/api/admin/spot-options/${adformat}`,
      data
    );
  }

  async getPermissions(params: { id: IUserExtended['id'] }) {
    return ApiService.http.get<void, { data: Permission[] }>(
      `/api/admin/users/${params.id}/permissions`
    );
  }

  async updatePermissions(params: { id: IUserExtended['id']; permissions: Permission[] }) {
    return ApiService.http.put<void, { data: Permission[] }>(
      `/api/admin/users/${params.id}/permissions`,
      { permissions: params.permissions }
    );
  }

  async getRolePermissions(params: { id: IUserExtended['id'] }) {
    return ApiService.http.get<void, { data: Permission[] }>(
      `/api/admin/roles/${params.id}/permissions`
    );
  }

  async updateRolePermissions(params: { id: IUserExtended['id']; permissions: Permission[] }) {
    return ApiService.http.put<void, { data: Permission[] }>(
      `/api/admin/roles/${params.id}/permissions`,
      { permissions: params.permissions }
    );
  }

  async getUserLabels() {
    return ApiService.http.get<void, IResponse<IUserLabel>>(`/api/admin/labels`);
  }

  async createUserLabel(options: IUserLabelOptions) {
    return ApiService.http.post<void, { data: IUserLabel }>(`/api/admin/labels`, options);
  }

  async updateUserLabel(id: IUserLabel['id'], options: IUserLabelOptions) {
    return ApiService.http.put<void, { data: IUserLabel }>(`/api/admin/labels/${id}`, options);
  }

  async removeUserLabel(id: IUserLabel['id']) {
    return ApiService.http.delete<void, void>(`/api/admin/labels/${id}`);
  }

  async getTechnicalAlerts() {
    return ApiService.http.get<void, IResponse<IUserTechnicalAlert>>(`/api/admin/technical_alerts`);
  }

  async createTechnicalAlert(options: IUserTechnicalAlertOptions) {
    return ApiService.http.post<void, { data: IUserTechnicalAlert }>(
      `/api/admin/technical_alerts`,
      options
    );
  }

  async updateTechnicalAlert(id: IUserTechnicalAlert['id'], options: IUserTechnicalAlertOptions) {
    return ApiService.http.put<void, { data: IUserTechnicalAlert }>(
      `/api/admin/technical_alerts/${id}`,
      options
    );
  }

  async removeTechnicalAlert(id: IUserTechnicalAlert['id']) {
    return ApiService.http.delete<void, void>(`/api/admin/technical_alerts/${id}`);
  }

  async getLandingTemplates() {
    return ApiService.http.get<void, { data: IUserLandingTemplate[] }>(
      `/api/admin/landing-templates`
    );
  }

  async createLandingTemplate(options: Omit<IUserLandingTemplate, 'id'>) {
    const formData = new FormData();

    if (options.image && options.image instanceof File) {
      formData.append('image', options.image);
    }

    (Object.keys(options) as Array<keyof typeof options>).forEach(key => {
      const value = options[key as keyof typeof options];

      if (key === 'image') return;
      if (['image_url', 'image_preview_url'].includes(key) && options.image) return;

      if (typeof value === 'boolean') {
        formData.append(key, (+value).toString());
        return;
      }

      if (value !== null && value !== undefined) {
        formData.append(key, value.toString());
      }
    });

    return ApiService.http.post<void, { data: IUserLandingTemplate }>(
      `/api/admin/landing-templates`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  async updateLandingTemplate(id: IUserLandingTemplate['id'], options: IUserLandingTemplate) {
    const formData = new FormData();

    if (options.image && options.image instanceof File) {
      formData.append('image', options.image);
    }

    formData.append('_method', 'PUT');

    (Object.keys(options) as Array<keyof typeof options>).forEach(key => {
      const value = options[key as keyof typeof options];

      if (key === 'image') return;
      if (['image_url', 'image_preview_url'].includes(key) && options.image) return;

      if (typeof value === 'boolean') {
        formData.append(key, (+value).toString());
        return;
      }

      if (value !== null && value !== undefined) {
        formData.append(key, value.toString());
      }
    });

    return ApiService.http.post<void, { data: IUserLandingTemplate }>(
      `/api/admin/landing-templates/${id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  async removeLandingTemplate(id: IUserLandingTemplate['id']) {
    return ApiService.http.delete<void, void>(`/api/admin/landing-templates/${id}`);
  }

  async getSpotLabels(params: Partial<IDataTableQuery>) {
    return ApiService.http.get<void, { data: ISpotCustomLabel[] }>(
      `/api/admin/custom-spot-labels`,
      { params }
    );
  }

  async createSpotLabel(params: Pick<ISpotCustomLabel, 'name'>) {
    return ApiService.http.post<void, { data: ISpotCustomLabel }>(
      `/api/admin/custom-spot-labels`,
      params
    );
  }

  async removeSpotLabel(id: ISpotCustomLabel['id']) {
    return ApiService.http.delete<void, void>(`/api/admin/custom-spot-labels/${id}`);
  }

  async getFingerprints(userId: IFingerprintUser['id'], params: IDataTableQuery) {
    return ApiService.http.get<void, IResponse<IFingerprint>>(
      `/api/admin/users/${userId}/fingerprints`,
      { params }
    );
  }

  async getFingerprintUsers(id: IFingerprintUser['id']) {
    return ApiService.http.get<void, { data: IFingerprintUser[] }>(
      `/api/admin/fingerprints/${id}/users`
    );
  }

  async updateFingerprintStatus(
    id: IFingerprintUser['id'],
    params: Pick<IFingerprintUser, 'banned' | 'ban_reason'>
  ) {
    return ApiService.http.put<void, void>(`/api/admin/fingerprints/${id}`, params);
  }

  async getSessionHistory(userId: IUserExtended['id']) {
    return ApiService.http.get<void, IResponse<ISession>>(`/api/admin/users/${userId}/sessions`);
  }

  async getEmailHistory(userId: IUserExtended['id']) {
    return ApiService.http.get<void, IResponse<IEmailHistory>>(
      `/api/admin/users/${userId}/email-changes`
    );
  }

  async banUserWithFingerprints(
    userId: IUserExtended['id'],
    params: Pick<IFingerprintUser, 'ban_reason'>
  ) {
    return ApiService.http.post<void, void>(`/api/admin/users/${userId}/fingerprints/ban`, params);
  }

  async unbanUserWithFingerprints(userId: IUserExtended['id']) {
    return ApiService.http.post<void, void>(`/api/admin/users/${userId}/fingerprints/unban`);
  }

  async getAdNetworks() {
    return ApiService.http.get<void, { data: IAdNetwork[] }>('/api/adnetworks');
  }

  async getAdNetworkAccounts(userID: IUserExtended['id']) {
    return ApiService.http.get<void, { data: IAdNetworkAccount[] }>(
      `/api/admin/users/${userID}/adnetworks-accounts`
    );
  }

  async addAdNetworkAccount(
    params: Omit<IAdNetworkAccount, 'account_id'>,
    userID: IUserExtended['id']
  ) {
    return ApiService.http.post<void, { data: IAdNetworkAccount }>(
      `/api/admin/users/${userID}/adnetworks-accounts`,
      params
    );
  }

  async updateAdNetworkAccount(
    params: Omit<IAdNetworkAccount, 'ad_network_id'>,
    userID: IUserExtended['id']
  ) {
    return ApiService.http.put<void, { data: IAdNetworkAccount }>(
      `/api/admin/users/${userID}/adnetworks-accounts/${params.account_id}`,
      params
    );
  }

  async removeAdNetworkAccount(
    params: Pick<IAdNetworkAccount, 'account_id'> & { user_id: IUserExtended['id'] | null }
  ) {
    return ApiService.http.delete<void, void>(
      `/api/admin/users/${params.user_id}/adnetworks-accounts/${params.account_id}`,
      { params }
    );
  }

  async getAdNetworkSpotLinks(
    params: Nullable<
      Pick<IAdNetworkSpotLink, 'adformat' | 'spot_id'> & {
        user_id: IUserExtended['id'];
        tag_id?: IAdNetworkSpotLink['tag_id'];
        ad_network_id?: IAdNetworkSpotLink['ad_network_id'];
      }
    >
  ) {
    return ApiService.http.get<void, { data: IAdNetworkSpotLink[] }>(
      `/api/admin/users/${params.user_id}/adnetworks-spots`,
      { params }
    );
  }

  async linkAdNetworkSpot(
    params: Omit<IAdNetworkSpotLink, 'id' | 'status'>,
    userID: IUserExtended['id']
  ) {
    return ApiService.http.post<void, { data: IAdNetworkSpotLink }>(
      `/api/admin/users/${userID}/adnetworks-spots`,
      params
    );
  }

  async updateAdNetworkSpot(
    params: Omit<IAdNetworkSpotLink, 'status'>,
    userID: IUserExtended['id']
  ) {
    return ApiService.http.patch<void, { data: IAdNetworkSpotLink }>(
      `/api/admin/users/${userID}/adnetworks-spots`,
      params
    );
  }

  async removeAdNetworkSpotLink(
    params: Omit<
      IAdNetworkSpotLink,
      'id' | 'link_type' | 'direct' | 'script' | 'status' | 'percentage' | 'device_type'
    >,
    userID: IUserExtended['id']
  ) {
    return ApiService.http.delete<void, void>(`/api/admin/users/${userID}/adnetworks-spots`, {
      params,
    });
  }

  async getAdNetworkSpots(
    params: Nullable<
      Pick<IAdNetworkSpotLink, 'adformat' | 'account_id'> & { user_id: IUserExtended['id'] }
    >
  ) {
    return ApiService.http.get<void, { data: IAdNetworkSpot[] }>(
      `/api/admin/users/${params.user_id}/adnetworks-available-spots/${params.adformat}`,
      { params }
    );
  }

  async getAdNetworkSpotLinkDefaults() {
    return ApiService.http.get<void, { data: Required<Pick<IAdNetworkSpotLink, 'percentage'>> }>(
      `/api/adnetworks-spots/defaults`
    );
  }

  async updateAdNetwortSpotLinkStatus(
    id: IAdNetworkSpotLink['id'],
    params: Pick<IAdNetworkSpotLink, 'status' | 'ad_network_id'>
  ) {
    return ApiService.http.patch<void, { data: IAdNetworkSpotLink }>(
      `/api/adnetworks-spots/${id}/status`,
      params
    );
  }

  async autoconnectAdNetworks(
    userID: number,
    params: {
      ad_network_ids: IAdNetwork['id'][];
    }
  ) {
    return ApiService.http.post<void, { data: Record<IAdNetwork['id'], boolean> }>(
      `/api/admin/users/${userID}/autoconnect-adnetworks`,
      params
    );
  }

  async getOwnOffers(
    params: Nullable<Pick<IOwnOffer, 'adformat' | 'spot_id'>> &
      Partial<IDataTableQuery> & { user_id: IUserExtended['id'] }
  ) {
    return ApiService.http.get<void, IResponse<IOwnOffer>>(`/api/admin/own_offers`, { params });
  }

  async getOwnOffer(params: { id: IUserExtended['id'] }) {
    return ApiService.http.get<void, { data: IOwnOffer }>(`/api/admin/own_offers/${params.id}`);
  }

  async createOwnOffer(
    params: Omit<IOwnOffer, 'id' | 'permissions' | 'account_id' | 'status'> & {
      user_id?: IUserExtended['id'];
    }
  ) {
    return ApiService.http.post<void, void>(`/api/admin/own_offers`, params);
  }

  async updateOwnOffer(params: Omit<IOwnOffer, 'permissions' | 'status'>) {
    return ApiService.http.put<void, void>(`/api/admin/own_offers/${params.id}`, params);
  }

  async removeOwnOffer(params: { id: IUserExtended['id'] }) {
    return ApiService.http.delete<void, void>(`/api/admin/own_offers/${params.id}`);
  }

  async getOwnOfferLinkDefaults() {
    return ApiService.http.get<void, { data: Required<Pick<IOwnOffer, 'percentage'>> }>(
      `/api/own_offers/defaults`
    );
  }

  async getOwnOfferLinkTypes() {
    return ApiService.http.get<void, { data: Record<AdFormat, IOwnOffer['link_type'][]> }>(
      '/api/own_offers/link-types'
    );
  }

  async updateOwnOfferStatus(id: IOwnOffer['id'], params: Pick<IOwnOffer, 'status'>) {
    return ApiService.http.patch<void, { data: IOwnOffer }>(`/api/own_offers/${id}/status`, params);
  }

  async addFAQArticle(params: Pick<IFAQArticle, 'name' | 'category_id' | 'body'>) {
    return ApiService.http.post<void, { data: IFAQArticle[] }>('/api/admin/faq-articles', params);
  }

  async updateFAQArticle(
    id: IFAQArticle['id'],
    params: Pick<IFAQArticle, 'name' | 'category_id' | 'body'>
  ) {
    return ApiService.http.put<void, { data: IFAQArticle[] }>(
      `/api/admin/faq-articles/${id}`,
      params
    );
  }

  async removeFAQArticle(id: IFAQArticle['id']) {
    return ApiService.http.delete<void, void>(`/api/admin/faq-articles/${id}`);
  }

  async addFAQCategory(params: Pick<IFAQCategory, 'name'>) {
    return ApiService.http.post<void, { data: IFAQCategory[] }>(
      '/api/admin/faq-categories',
      params
    );
  }

  async updateFAQCategory(id: IFAQCategory['id'], params: Pick<IFAQCategory, 'name'>) {
    return ApiService.http.put<void, { data: IFAQCategory[] }>(
      `/api/admin/faq-categories/${id}`,
      params
    );
  }

  async yandexAuth() {
    return ApiService.http.get<void, { data: { link: string } }>(`/api/yandex/auth`);
  }

  async getMaintenance() {
    return ApiService.http.get<
      void,
      {
        data: {
          maintenance: boolean;
        };
      }
    >('/api/maintenance');
  }

  async getMetaData() {
    return ApiService.http.get<
      void,
      {
        data: {
          created_requests: number;
        };
      }
    >('/api/admin/meta');
  }

  async getRoles() {
    return ApiService.http.get<void, { data: Record<string, IRole['id']> }>(`/api/admin/roles`);
  }

  async addRole(params: { name: string; permissions: Permission[] }) {
    return ApiService.http.post<void, { data: { name: string; permissions: Permission[] } }>(
      `/api/admin/roles`,
      params
    );
  }

  async moderateTagCategory(tagID: number, category_id: number) {
    return ApiService.http.patch<
      void,
      { data: Omit<ITag, 'created_at' | 'updated_at' | 'options'> }
    >(`/api/moderation/tags/${tagID}`, { category_id });
  }

  async getAntiFraudVerdictGraduations(adFormat: AdFormat) {
    return ApiService.http.get<void, { data: IAntiFraudVerdictGraduationList }>(
      `/api/admin/antifraud-verdicts-graduations/${adFormat}`
    );
  }

  async updateAntiFraudVerdictGraduations(
    adFormat: AdFormat,
    graduation: IAntiFraudVerdictGraduationList
  ) {
    return ApiService.http.put<void, { data: IAntiFraudVerdictGraduationList }>(
      `/api/admin/antifraud-verdicts-graduations/${adFormat}`,
      graduation
    );
  }

  async getTrafficQualityReportFormats(
    query: string,
    params: Partial<IDataTableQuery> & {
      user_ids: IUserExtended['id'][];
      spot_ids: Spot['id'][];
    }
  ) {
    return ApiService.http.get<void, { data: TrafficQualityReportFormatsResponse }>(
      `/api/admin/traffic-quality-formats?${query}`,
      { params }
    );
  }

  async getTrafficQualityReport<T extends AdFormat>(
    adFormat: T,
    params: Partial<IDataTableQuery> & {
      user_ids: IUserExtended['id'][];
      spot_ids: Spot['id'][];
      version?: 'ban';
    }
  ) {
    return ApiService.http.get<void, { data: TrafficQualityReport<T> }>(
      `/api/admin/traffic-quality/${adFormat}`,
      { params }
    );
  }

  async getTrafficQualityRequest<T extends AdFormat>(
    adFormat: T,
    version: ITrafficQuality['version']
  ) {
    return ApiService.http.get<void, { data: TrafficQualityReport<T> }>(
      `/api/admin/traffic-quality/${adFormat}/${version}/query`
    );
  }

  async fetchTrafficQualityOptions(params: Partial<IDataTableQuery>) {
    return ApiService.http.get<void, IResponse<ITrafficQuality>>(
      `/api/admin/traffic-quality-option`,
      {
        params,
      }
    );
  }

  async createTrafficQualityOption(
    params: Pick<ITrafficQuality, 'adformat' | 'options' | 'version'>
  ) {
    return ApiService.http.post<void, IResponse<ITrafficQuality>>(
      `/api/admin/traffic-quality-option`,
      params
    );
  }

  async updateTrafficQualityOption(
    id: ITrafficQuality['id'],
    params: { options: ITrafficQuality['options'] }
  ) {
    return ApiService.http.put<void, IResponse<ITrafficQuality>>(
      `/api/admin/traffic-quality-option/${id}`,
      params
    );
  }

  async deleteTrafficQualityOption(id: ITrafficQuality['id']) {
    return ApiService.http.delete<void, void>(`/api/admin/traffic-quality-option/${id}`);
  }

  async recalculateTrafficQuality(
    adFormat: ITrafficQuality['adformat'],
    version: ITrafficQuality['version']
  ) {
    return ApiService.http.get<void, void>(
      `/api/admin/traffic-quality/${adFormat}/${version}/recalculation`
    );
  }

  async getPaymentRequests(params: Partial<IDataTableQuery> & IPaymentRequestsQuery) {
    (this as any).cancel?.(); // TODO: Refactor
    return ApiService.http.get<void, IResponse<IPaymentRequest>>('/api/admin/payment-requests', {
      params,
      cancelToken: new axios.CancelToken((c: any) => ((AdminService as any).cancel = c)),
    });
  }

  async createPaymentRequest(params: { amount?: number }) {
    return ApiService.http.post<void, { data: IPaymentRequest }>(
      '/api/admin/payment-requests',
      params
    );
  }

  async updatePaymentRequests(
    params: {
      request_id: number;
      bitmask: number;
      reject_reason?: string;
      premoderation?: {
        status:
          | typeof PAYMENT_REQUEST_PRE_APPROVE_STATUS
          | typeof PAYMENT_REQUEST_PRE_REJECT_STATUS;
        comment: string | null;
      };
    }[]
  ) {
    return ApiService.http.patch<
      void,
      {
        data: {
          requests: IPaymentRequest[];
          errors: {
            request_id: number;
            text: string;
          }[];
        };
      }
    >(`/api/admin/payment-requests`, params);
  }

  async getUsersWithNoPayout(params: IDataTableQuery) {
    return ApiService.http.get<
      void,
      IResponse<{
        id: number;
        user_email: string;
        manager_email: string | null;
        balance: string;
      }> & { total_balance: string }
    >(`/api/admin/payment-requests/have-no-payouts`, { params });
  }

  async createBatchPayments(
    params: { options: any; payment_method_name: string; request_ids: number[] },
    config: any
  ) {
    return ApiService.http.post<void, string>('/api/admin/payment-requests/batch', params, config);
  }

  async downloadBatchesCSV(
    params: Pick<IPaymentRequestsQuery, 'date_from' | 'date_to' | 'payment_systems'> & {
      status?: string | null;
    },
    config: any
  ) {
    return ApiService.http.post<void, string>(`/api/admin/batches/csv`, params, config);
  }

  async getBatches(params: { status?: string[] }) {
    (this as any).cancel?.(); // TODO: Refactor
    return ApiService.http.get<void, IResponse<IBatch>>('/api/admin/batches', {
      params,
      cancelToken: new axios.CancelToken((c: any) => ((AdminService as any).cancel = c)),
    });
  }

  async getBatch(id: number) {
    (this as any).cancel?.(); // TODO: Refactor
    return ApiService.http.get<void, { data: IBatch }>(`/api/admin/batches/${id}`, {
      cancelToken: new axios.CancelToken((c: any) => ((AdminService as any).cancel = c)),
    });
  }

  async createBatch(params: IBatchPayload) {
    return ApiService.http.post<void, { data: IBatch }>('/api/admin/batches', params);
  }

  async updateBatch(id: number, params: { status: string }) {
    return ApiService.http.patch<void, { data: IBatch }>(`/api/admin/batches/${id}`, params);
  }

  async getModerationRequests<T extends ModerationEntityType>(
    entityType: T,
    params: IDataTableQuery
  ) {
    return ApiService.http.get<void, IResponse<ModerationRequest<T>>>(
      `/api/admin/premoderations/${entityType}`,
      { params }
    );
  }

  async updateModerationRequest<T extends ModerationEntityType>(request: ModerationRequest<T>) {
    return ApiService.http.put<void, ModerationRequest<T>>(
      `/api/admin/premoderations/${request.id}`,
      request
    );
  }

  async createModerationRequest<T extends ModerationEntityType>(entityType: ModerationEntityType) {
    return (data: ModerationRequest<T>['data'] & { user_id: IUserExtended['id'] }) =>
      ApiService.http.post<void, void>(`/api/admin/premoderations/${entityType}`, data);
  }

  async getNotifications(params: Partial<IDataTableQuery>) {
    return ApiService.http.get<void, IResponse<INotificationRequest>>(`/api/admin/notifications`, {
      params,
    });
  }

  async createNotification(notification: Partial<Omit<INotificationRequest, 'id'>>) {
    return ApiService.http.post<void, { data: INotificationRequest }>(
      `/api/admin/notifications`,
      notification
    );
  }

  async updateNotification(notification: Partial<INotificationRequest>) {
    return ApiService.http.put<void, { data: INotificationRequest }>(
      `/api/admin/notifications/${notification.id}`,
      notification
    );
  }

  async deleteNotification(id: INotificationRequest['id']) {
    return ApiService.http.delete<void, void>(`/api/admin/notifications/${id}`);
  }

  async getNotificationRecipients({
    id,
    page = 1,
    limit = 10,
    search = '',
  }: {
    id: number;
    page: number;
    limit: number;
    search: string;
  }) {
    return ApiService.http.get<void, { data: { users: string[]; roles: [] }; meta: IMeta }>(
      `/api/admin/notifications/${id}/recipients?${qs.stringify(
        omitEmpty({ page, limit, search })
      )}`
    );
  }

  async getNotificationUsers(params: INotificationSegment) {
    return ApiService.http.get<void, { data: IUserExtended['email'][] }>(
      '/api/admin/notifications/users',
      { params }
    );
  }

  async fetchAdFormatRates(params: Partial<IRateQueryParams>) {
    return ApiService.http.get<void, IResponse<IRate>>('/api/admin/stats-adformat-rates', {
      params,
    });
  }

  async inviteUser(params: { email: string }) {
    return ApiService.http.post<void, void>(`/api/admin/send-user-invitation`, params);
  }

  async fetchAccrualRuleOfflineFixes(params: Partial<IAccrualRuleOfflineFixQuery>) {
    return ApiService.http.get<void, IResponse<IAccrualRuleOfflineFix>>(
      '/api/admin/offline-fixes/report',
      { params }
    );
  }

  async addAccrualRuleOfflineFix(
    userID: number,
    params: Pick<IAccrualRuleOfflineFix, 'amount' | 'date_from' | 'date_to'>
  ) {
    return ApiService.http.post<void, { data: IAccrualRuleOfflineFix }>(
      `/api/admin/users/${userID}/offline-fixes`,
      params
    );
  }

  async updateAccrualRuleOfflineFix(id: number, params: IUpsertAccrualRuleOfflineFix) {
    return ApiService.http.patch<void, void>(`/api/admin/offline-fixes/${id}`, {
      amount: params.amount,
      date_from: params.date_from,
      date_to: params.date_to,
    });
  }

  async deleteAccrualRuleOfflineFix(id: number) {
    return ApiService.http.delete<void, void>(`/api/admin/offline-fixes/${id}`);
  }

  async fetchPaymentRequestsApproveSettings() {
    return ApiService.http.get<void, { data: IPaymentRequestApproveSettings[] }>(
      '/api/admin/payment-request-approve-settings'
    );
  }

  async updatePaymentRequestsApproveSettings(settings: IPaymentRequestApproveSettings) {
    return ApiService.http.patch<void, { data: IPaymentRequestApproveSettings }>(
      `/api/admin/payment-request-approve-settings/${settings.adformat}`,
      settings
    );
  }

  async getUsersRestrictSuggestions(params: IUsersRestrictSuggestionsQuery) {
    return ApiService.http.get<void, IResponse<IUsersRestrictSuggestion>>(
      `/api/admin/users-restrict-suggestions`,
      { params }
    );
  }
  async fetchUserSpotOptions(id: IUserExtended['id']) {
    return ApiService.http.get<void, { data: IUserSpotOption[] }>(
      `/api/admin/users/${id}/spot-options`
    );
  }

  async fetchUserSpotOption(id: IUserSpotOption['id']) {
    return ApiService.http.get<void, { data: IUserSpotOption }>(
      `/api/admin/users/spot-options/${id}`
    );
  }

  async createUserSpotOption(
    params: Pick<IUserSpotOption, 'additional_options' | 'adformat' | 'options' | 'user_id'>
  ) {
    return ApiService.http.post<void, { data: IUserSpotOption }>(
      `/api/admin/users/spot-options`,
      params
    );
  }

  async updateUserSpotOption(
    id: IUserSpotOption['id'],
    params: Pick<IUserSpotOption, 'additional_options' | 'adformat' | 'options' | 'user_id'>
  ) {
    return ApiService.http.put<void, { data: IUserSpotOption }>(
      `/api/admin/users/spot-options/${id}`,
      params
    );
  }

  async deleteUserSpotOption(id: IUserSpotOption['id']) {
    return ApiService.http.delete<void, void>(`/api/admin/users/spot-options/${id}`);
  }

  async getLosses(params: IDataTableQuery & LossFilters) {
    return ApiService.http.get<void, IResponse<Loss>>(`/api/admin/publisher-fallout-data`, {
      params,
    });
  }

  async setConfirmStatusLoss(request: Pick<Loss, 'id' | 'reason' | 'comment'>) {
    return ApiService.http.patch<void, { data: Loss }>(
      `/api/admin/publisher-fallout-data-confirm/${request.id}`,
      request
    );
  }

  async setMistakeStatusLoss(request: Pick<Loss, 'id'>) {
    return ApiService.http.patch<void, { data: Loss }>(
      `/api/admin/publisher-fallout-data-mistake/${request.id}`
    );
  }

  async getLossReasons() {
    return ApiService.http.get<void, { data: Record<string, number> }>(
      `/api/admin/publisher-fallout-data-reasons`
    );
  }

  async getLossStatuses() {
    return ApiService.http.get<void, { data: Record<string, number> }>(
      `/api/admin/publisher-fallout-data-statuses`
    );
  }
}

export default new AdminService();
